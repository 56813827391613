import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  isCollapsed: true,
  groupsCollapsed: {
    transactions: true,
    epyme: true,
  },
  dimensions: {
    mobileHeight: 0,
    desktopWidth: 0,
  },
}

export const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    collapseSideBar: (state) => {
      state.isCollapsed = true
    },
    expandSideBar: (state) => {
      state.isCollapsed = false
    },
    collapseGroup: (state, action: PayloadAction<"transactions" | "epyme">) => {
      state.groupsCollapsed[action.payload] = true
    },
    expandGroup: (state, action: PayloadAction<"transactions" | "epyme">) => {
      state.groupsCollapsed[action.payload] = false
    },
    collapseAllGroups: (state) => {
      state.groupsCollapsed = {
        transactions: true,
        epyme: true,
      }
    },
    updateDimensions: (state, action) => {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          ...action.payload,
        },
      }
    },
  },
})

export const {
  collapseSideBar,
  expandSideBar,
  collapseGroup,
  expandGroup,
  updateDimensions,
  collapseAllGroups,
} = sideBarSlice.actions

export type SideBarState = typeof initialState

export default sideBarSlice.reducer
