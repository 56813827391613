import firebase from "firebase/app"
import {
  baseAuthenticationUrl,
  patchAxios,
  postAxios,
} from "../../../connectors/coordinatorConnector"
import { DeviceModel } from "../../user/interfaces/types"
import { AuthenticationMethod } from "../../../interfaces/types"

export function updatePasswordDate({ uid }: { uid: string }) {
  return firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then((idToken: string) => {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${uid}/password-date`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      )
    })
}

export function sendPasswordChangeEmail({ uid }: { uid: string }) {
  return firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then((idToken: string) => {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${uid}/successful-password-change`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      )
    })
}

export function createSession({
  uid,
  deviceModel,
  deviceDescription,
  browserType,
  deviceUid,
}: {
  uid: string
  deviceModel: DeviceModel
  deviceDescription: string
  browserType: string
  deviceUid?: string
}) {
  return firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then((idToken: string) => {
      return postAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${uid}/sessions`,
        {
          device: {
            model: deviceModel,
            description: deviceDescription,
            uid: deviceUid,
          },
          browserType: browserType,
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      )
    })
}

export function refreshSession({
  userId,
  sessionId,
}: {
  userId: string
  sessionId: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/sessions/${sessionId}/refresh`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function disconnectAllSession({ userId }: { userId: string }) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/sessions/sign-out`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function disconnectSession({
  userId,
  sessionId,
}: {
  userId: string
  sessionId: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/sessions/${sessionId}/sign-out`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function disconnectCurrentSession({ userId }: { userId: string }) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/log-out`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function expireSession({
  userId,
  sessionId,
}: {
  userId: string
  sessionId: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/sessions/${sessionId}/expire`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

// 2FA

export function validateIdentityByEmail(userId: string) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/validate-identity-by-email/request`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function signIdentityByEmail({
  userId,
  requestId,
  token,
}: {
  userId: string
  requestId: string
  token: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/validate-identity-by-email/sign`,
        {
          requestId,
        },
        {
          headers: { Authorization: `Bearer ${idToken}`, "X-Token": token },
        }
      )
    })
}

export function register2FAMethod({
  userId,
  phoneNumber,
  requestId,
  device,
  authenticationMethod,
}: {
  userId: string
  phoneNumber: string
  requestId: string
  device: {
    model: string
    description: string
    uid: string
  }
  authenticationMethod: AuthenticationMethod
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/request-authentication-method-register`,
        {
          phoneNumber,
          requestId,
          device,
          authenticationMethod,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function sign2FAMethod({
  userId,
  token,
  authenticationMethodRecordId,
  authenticationDailyRecordId,
  device,
}: {
  userId: string
  token: string
  authenticationMethodRecordId: string
  authenticationDailyRecordId: string
  device: {
    model: string
    description: string
    uid: string
  }
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/sign-authentication-method-register`,
        {
          authenticationMethodRecordId,
          authenticationDailyRecordId,
          device,
        },
        {
          headers: { Authorization: `Bearer ${idToken}`, "X-Token": token },
        }
      )
    })
}

export function resendToken2FA({
  userId,
  authenticationMethodRecordId,
  deviceUid,
}: {
  userId: string
  authenticationMethodRecordId: string
  deviceUid: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/authentication-method-records/${authenticationMethodRecordId}/register-method/regenerate-token`,
        { deviceUid },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function dailyRegister({
  userId,
  device,
}: {
  userId: string
  device: {
    model: DeviceModel
    description: string
    uid: string
  }
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAuthenticationUrl}/bizarrap/authentication-daily-records/request-daily-register`,
        { userId, device },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function signDailyRegister({
  userId,
  authenticationDailyRecordId,
  device,
  token,
}: {
  userId: string
  token: string
  authenticationDailyRecordId: string
  device: {
    model: DeviceModel
    description: string
    uid: string
  }
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/authentication-daily-records/sign-daily-register`,
        {
          userId,
          authenticationDailyRecordId,
          device,
        },
        {
          headers: { Authorization: `Bearer ${idToken}`, "X-Token": token },
        }
      )
    })
}

export function resendDailyRegisterToken({
  userId,
  authenticationDailyRecordId,
  deviceUid,
}: {
  userId: string
  authenticationDailyRecordId: string
  deviceUid: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/authentication-daily-records/${authenticationDailyRecordId}/regenerate-token`,
        { deviceUid },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function change2FAMethod({
  phoneNumber,
  deviceUid,
  requestId,
  userId,
  authenticationMethod,
}: {
  phoneNumber: string
  deviceUid: string
  requestId: string
  userId: string
  authenticationMethod: AuthenticationMethod
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/authentication-method-records/change-method-request`,
        {
          phoneNumber,
          deviceUid,
          requestId,
          authenticationMethod,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export function signChange2FAMethod({
  userId,
  authenticationMethodRecordId,
  token,
}: {
  userId: string
  authenticationDailyRecordId: string
  authenticationMethodRecordId: string
  token: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/authentication-method-records/sign-change-method`,
        {
          authenticationMethodRecordId,
        },
        {
          headers: { Authorization: `Bearer ${idToken}`, "X-Token": token },
        }
      )
    })
}

export function resend2FAMethodToken({
  userId,
  authenticationMethodRecordId,
  deviceUid,
}: {
  userId: string
  authenticationMethodRecordId: string
  deviceUid: string
}) {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return patchAxios(
        `${baseAuthenticationUrl}/bizarrap/users/${userId}/authentication-method-records/${authenticationMethodRecordId}/change-method/regenerate-token`,
        { deviceUid },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}
