import React, { useState } from "react"
import styles from "./Button.module.scss"
import LoadingCircles from "./LoadingCircles"

type PrimaryProps = {
  "aria-label": string
  "data-action": string
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  role: string
  title: string
}

export type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "primaryDarker"
  | "secondaryDarker"
  | "tertiaryDarker"

export interface ButtonProps {
  text: string
  buttonType: ButtonType
  type: "button" | "submit"
  position?: "start" | "middle" | "finish"
  disabled?: boolean
  click?: React.MouseEventHandler<HTMLButtonElement>
  fontAwesomeIconClass?: string
  leftMargin?: boolean
  width?: string
  className?: string
  isLoading?: boolean
  innerRef?: React.MutableRefObject<HTMLButtonElement | null>
  tourProps?: PrimaryProps
  tabIndex?: number
  icon?: React.ReactElement
  iconPosition?: "left" | "right"
  hoverIcon?: React.ReactElement
}

const Button = (props: ButtonProps) => {
  const {
    isLoading,
    buttonType,
    position,
    leftMargin,
    className,
    type,
    disabled,
    click,
    width,
    innerRef,
    tabIndex,
    tourProps,
    iconPosition,
    text,
    fontAwesomeIconClass,
    icon,
    hoverIcon,
  } = props

  const buttonClass = isLoading ? `${buttonType}Loading` : buttonType

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <button
      className={`${styles[buttonClass]} ${
        (position && styles[position]) ?? ""
      } ${leftMargin ? "ml-3" : "m-0"} ${className ?? ""}`}
      type={type}
      disabled={disabled}
      onMouseDown={(e) => e.preventDefault()}
      onClick={isLoading ? () => null : (event) => click && click(event)}
      onMouseEnter={hoverIcon && handleMouseEnter}
      onMouseLeave={hoverIcon && handleMouseLeave}
      style={width ? { width } : {}}
      ref={innerRef}
      tabIndex={tabIndex}
      {...tourProps}
    >
      {isLoading ? (
        <LoadingCircles greyVariant={disabled} />
      ) : (
        <>
          {(iconPosition === "right" || !iconPosition) && text}
          {fontAwesomeIconClass && (
            <span className={`${fontAwesomeIconClass} m-0 p-0 ml-2`} />
          )}
          {isHovered && hoverIcon ? <>{hoverIcon}</> : <>{icon}</>}
          {iconPosition === "left" && text}
        </>
      )}
    </button>
  )
}

export default Button
