import balance from "./reducers/balance"
import { combineReducers } from "redux"
import sidebar from "./reducers/sidebar"
import authentication from "./reducers/authentication"
import { firebaseReducer } from "react-redux-firebase"
import { firestoreReducer } from "redux-firestore"
import newTransactionForm from "./reducers/newTransactionForm"
import newMassiveTransationForm from "./reducers/newMassiveTransactionForm"
import newMassiveTransation from "./reducers/newMassiveTransation"
import newLinkForm from "./reducers/newLinkForm"
import newContactForm from "./reducers/newContactForm"
import newTransferRequestForm from "./reducers/newTransferRequestForm"
import contacts from "./reducers/contacts"
import transactions from "./reducers/transactions"
import theme from "./reducers/theme"
import updateServiceWorker from "./reducers/updateServiceWorker"
import contactReducer from "./reducers/contactReducer"
import messageReducer from "./reducers/messageReducer"
import accountSettingsReducer from "./reducers/accountSettingsReducer"
import solicitudes from "./reducers/solicitudes"
import newSolicitudeForm from "./reducers/newSolicitudeForm"
import holidaysReducer from "./reducers/holidaysReducer"
import newPromissoryNoteForm from "./reducers/newPromissoryNoteForm"
import promissoryNotesReducer from "./reducers/promissoryNotesReducer"
import newEcheqForm from "./reducers/newEcheqForm"
import echeqsReducer from "./reducers/echeqsReducer"
import newServicePaymentForm from "./reducers/newServicePaymentForm"
import windowWidth from "./reducers/windowWidthReducer"
import newLinkageForm from "./reducers/newLinkageForm"
import transactionRequestSelected from "./reducers/transactionRequestSelected"
import newTransaction from "./reducers/newTransaction"
import modalReducer from "./reducers/modalReducer"
import newInviteForm from "./reducers/newInviteForm"
import roleInformationModal from "./reducers/roleInformationModal"
import userManagementSearch from "./reducers/userManagementSearch"
import accountSelectionReducer from "./reducers/accountSelectionReducer"
import linkageModal from "./reducers/linkageModal"
import newSectionTags from "./reducers/newSectionTags"
import modalBodyDimensions from "./reducers/modalBodyDimensions"
import fundsManagementModal from "./reducers/fundsManagementModal"
import paymentLinks from "./reducers/paymentLinks"
import cardModal from "./reducers/cardModal"
import consumptionModal from "./reducers/consumptionModal"
import cardSensitiveData from "./reducers/cardSensitiveData"
import orderIntentionsModal from "./reducers/orderIntentionsModal"
import whitelist from "./reducers/whitelist"
import accountsForUser from "./reducers/accountsForUser"
import fundsData from "./reducers/fundsData"
import session from "./reducers/session"
import newServicePayment from "./reducers/newServicePayment"
import twoFactorAuthentication from "./reducers/twoFactorAuthentication"
import user from "./reducers/user"
import device from "./reducers/device"
import echeqModal from "./reducers/echeqModal"
import allariaCxuModal from "./reducers/allariaCxuModal"

//let isMock = false;

// expose store when run in Cypress
/* if (window.Cypress) {
  isMock = true;
} */

const appReducer = combineReducers({
  balance,
  sidebar,
  authentication,
  newTransactionForm,
  newTransaction,
  newMassiveTransationForm,
  newMassiveTransation,
  newLinkForm,
  newContactForm,
  newTransferRequestForm,
  solicitudes,
  newSolicitudeForm,
  contactReducer,
  messageReducer,
  contacts,
  transactions,
  theme,
  updateServiceWorker,
  accountSettingsReducer,
  promissoryNotesReducer,
  holidaysReducer,
  newPromissoryNoteForm,
  newEcheqForm,
  echeqsReducer,
  firebase: firebaseReducer,
  // firestore: isMock ? firestoreMockReducer : firestoreReducer,
  firestore: firestoreReducer,
  newServicePaymentForm,
  windowWidth,
  newLinkageForm,
  transactionRequestSelected,
  modalReducer,
  newInviteForm,
  roleInformationModal,
  userManagementSearch,
  linkageModal,
  fundsManagementModal,
  modalBodyDimensions,
  paymentLinks,
  accountSelectionReducer,
  newSectionTags,
  cardModal,
  consumptionModal,
  cardSensitiveData,
  orderIntentionsModal,
  whitelist,
  accountsForUser,
  fundsData,
  session,
  newServicePayment,
  twoFactorAuthentication,
  user,
  device,
  echeqModal,
  allariaCxuModal
})

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    // This will reset the state to its initial value
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
