export const PATH_APP = {
  //Password
  changePassword: "/change-password",
  passwordSafety: "/password",
  resetPassword: "/reset-password",

  //Login
  login: "/login",
  loadingBlank: "/loading-blank",

  //Dashboard
  multiAccountDashboard: "/",
  individualDashboard: "/dashboard",

  //Promissory Notes
  promissoryNotes: "/pagares",
  promissoryNoteDetail: "/pagares/:id",
  newPromissoryNote: "/nuevo-pagare",

  //Echeqs
  echeqs: "/echeqs",
  echeqsDetail: "/echeqs/:id",
  newEcheq: "/nuevo-echeq",

  //Contacts
  contacts: "/contactos",
  newContact: "/nuevo-contacto",
  newContactConfirm: "/nuevo-contacto-confirmar",
  newContactApprove: "/nuevo-contacto-aprobar",
  newContactFinish: "/nuevo-contacto-fin",
  editContactSuccess: "/editar-contacto-success",
  approveContact: "/aprobar-contacto",
  editContact: "/contactos/:id",

  //Transactions
  newTransaction: "/nueva-transferencia",
  newMassiveTransaction: "/nueva-transferencia-masiva",

  //Reports
  reportSuccess: "/reporte/succes",
  reports: "/reportes",

  //Config
  config: "/configuracion",

  //Investments
  investments: "/inversiones",

  //FAQS
  faqs: "/preguntas-frecuentes",

  //TransferRequests
  allariaTransferRequest: "/allaria-autorizar-solicitud-de-transferencia",

  //Epymes
  epymeTyc: "/epyme-tyc",

  //Service Payments
  servicePayment: "/pago-de-servicios",

  //Movements
  movements: "/movimientos",

  //User Management
  userManagement: "/gestion-usuarios",
  guestInvitationForm: "/invitacion-usuario",

  //Portfolio Visualization
  // portfolioVisualization: "/cartera",

  //Allaria Ecosystem
  allariaEcosystem: "/ecosistema-allaria",

  //Cards
  cards: "/tarjetas",
  cardsActivation: "/tarjeta-allaria",

  //Payment links
  paymentLinks: "/links-de-pago",
  paymentLinkCustomer: "/link-de-pago",

  //Movements
  notFound: "*",
}
