import { createSlice } from "@reduxjs/toolkit"
import { VepServices } from "../interfaces/types"
import {
  AnyServiceProvider,
  PaymentPeriodSelection,
  PaymentTypeStep,
  ProviderPaymentStep,
} from "../components/servicePayments/interfaces/types"
import { InvoiceItem } from "../components/servicePayments/interfaces/types"

interface VepPayment {
  externalCode: string
  amount: string
  cuitGenerator: string
  cuitTaxPayer: string
  expirationDate: string
  vepCode: string
  vepServices: VepServices[]
  serviceProviderName: string
  description: string
  taxAmount: number
  debitedAmount: string
}

interface ArbaPayment {
  amount: string
  expirationDate: string
  serviceProviderName: string
  serviceProviderCode: string
  arbaCode: string
  debitedAmount: string
  taxAmount: string
  externalCode: string
}

interface ProviderPayment {
  paymentPeriod: PaymentPeriodSelection
  clientNumber: string
  scheduledService: {
    save: boolean
    alias: string
  } | null
  invoiceSelected: {
    id: string
    amount: number
    description: string
    isAnnual: string
    serviceProviderId: string
    dueDate: Date
    currency?: string
  } | null
  description: string
  amount: string
  amountUSD: string | null
  debitedAmount: string
  taxAmount: string
  serviceProviderName: string
}

interface modalState {
  open: boolean
  currentStep: PaymentTypeStep
  currentProviderPayment: ProviderPaymentStep
  prevProviderPaymentStep: ProviderPaymentStep
}

interface InitialState {
  modal: modalState
  vepPayment: VepPayment | null
  arbaPayment: ArbaPayment | null
  providerPayment: ProviderPayment | null
  providerData: AnyServiceProvider | null
  invoicesCart: InvoiceItem[] | []
  options: {
    addDolarInvoiceToCart: boolean
  }
}

export const newServicePaymentInitialState: InitialState = {
  modal: {
    open: false,
    currentStep: "PROVIDER_PAYMENT",
    currentProviderPayment: "SET_IDENTIFICATION_NUMBER",
    prevProviderPaymentStep: "SET_IDENTIFICATION_NUMBER",
  },
  options: { addDolarInvoiceToCart: false },
  vepPayment: null,
  arbaPayment: null,
  providerPayment: null,
  providerData: null,
  invoicesCart: [],
}

const newPaymentModal = createSlice({
  name: "New Payment Modal",
  initialState: newServicePaymentInitialState,
  reducers: {
    // reset store
    resetNewPaymentModal: () => {
      return newServicePaymentInitialState
    },
    // reset modal state
    resetNewPaymentModalState: (state) => {
      return {
        ...state,
        modal: {
          open: false,
          currentStep: "PROVIDER_PAYMENT",
          currentProviderPayment: "SET_IDENTIFICATION_NUMBER",
          prevProviderPaymentStep: "SET_IDENTIFICATION_NUMBER",
        },
      }
    },
    updateNewPaymentModalState: (state, action: { payload: modalState }) => {
      return { ...state, modal: action.payload }
    },
    updateNewPaymentModal: (state, action) => {
      return { ...state, ...action.payload }
    },
    //Cart Functions
    addInvoiceToCart: (state, action) => {
      const { payload } = action
      const uniqueIds = new Set()
      const newCart = [...state.invoicesCart, payload]
      const duplicatesFilter = newCart.filter((inv) => {
        if (!uniqueIds.has(inv.id)) {
          uniqueIds.add(inv.id)
          return true
        }
        return false
      })
      return { ...state, invoicesCart: duplicatesFilter }
    },
    addAllInvoicesToCart: (state, action) => {
      const { payload } = action
      const uniqueIds = new Set()
      const newCart = [...state.invoicesCart, ...payload]
      const duplicatesFilter = newCart.filter((inv) => {
        if (!uniqueIds.has(inv.id)) {
          uniqueIds.add(inv.id)
          return true
        }
        return false
      })
      return { ...state, invoicesCart: duplicatesFilter }
    },
    removeInvoiceFromCart: (state, action) => {
      const { payload } = action
      const currenCart: InvoiceItem[] = state.invoicesCart
      const newCart = currenCart.filter((inv) => inv.id !== payload.id)
      return { ...state, invoicesCart: newCart }
    },
    removeInvoicesFromCart: (state, action) => {
      const { payload } = action
      const invoicesIds = new Set(payload.map((inv: InvoiceItem) => inv.id))
      const newCart = state.invoicesCart.filter(
        (inv) => !invoicesIds.has(inv.id)
      )
      return { ...state, invoicesCart: newCart }
    },
    resetCart: (state) => {
      return { ...state, invoicesCart: [] }
    },
    handleAddDolarState: (state, action) => {
      const { payload } = action
      return {
        ...state,
        options: {
          addDolarInvoiceToCart: payload,
        },
      }
    },
  },
})

export const {
  resetNewPaymentModal,
  resetNewPaymentModalState,
  updateNewPaymentModalState,
  updateNewPaymentModal,
  addInvoiceToCart,
  addAllInvoicesToCart,
  removeInvoiceFromCart,
  removeInvoicesFromCart,
  resetCart,
  handleAddDolarState,
} = newPaymentModal.actions

export default newPaymentModal.reducer
