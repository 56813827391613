import lazyCatchReload from "../components/utils/lazyCatchReload"
import LoadingBlankPage from "../components/loadingBlankPage/LoadingBlankPage"
import { PATH_APP } from "./paths"

const ChangePassword = lazyCatchReload(
  () => import("../components/authentication/ChangePassword")
)
const PasswordReset = lazyCatchReload(
  () => import("../components/authentication/PasswordReset")
)
const Login = lazyCatchReload(
  () => import("../components/authentication/Login")
)
const PasswordSafety = lazyCatchReload(
  () => import("../components/authentication/PasswordSafety")
)
const App = lazyCatchReload(() => import("../components/app/App"))
const Landing = lazyCatchReload(() => import("../components/landings/Landing"))
const LoanLanding = lazyCatchReload(
  () => import("../components/landings/LoanLanding")
)
const GuestInvitationForm = lazyCatchReload(
  () => import("../components/userManagement/GuestInvitationForm")
)

const PaymentLink = lazyCatchReload(
  () => import("../components/paymentLinks/ui/PaymentLinkForm/PaymentLinkForm")
)

export const indexRoutesList = [
  {
    name: "Payment link",
    path: PATH_APP.paymentLinkCustomer,
    children: <PaymentLink />,
  },
  {
    name: "Invite User",
    path: PATH_APP.guestInvitationForm,
    children: <GuestInvitationForm />,
  },
  {
    name: "Loan Landing",
    path: "/prestamos",
    children: <LoanLanding />,
  },
  {
    name: "Landing",
    path: "/evento-3GE",
    children: <Landing />,
  },
  {
    name: "Change password",
    path: PATH_APP.changePassword,
    children: <ChangePassword />,
  },
  {
    name: "Password safety",
    path: PATH_APP.passwordSafety,
    children: <PasswordSafety />,
  },
  {
    name: "Login",
    path: PATH_APP.login,
    children: <Login />,
  },
  {
    name: "Password reset",
    path: PATH_APP.resetPassword,
    children: <PasswordReset />,
  },
  {
    name: "Loading blank",
    path: PATH_APP.loadingBlank,
    children: <LoadingBlankPage />,
  },
  {
    name: "App",
    path: PATH_APP.multiAccountDashboard,
    children: <App />,
  },
]
